$main-font: "Muli", sans-serif;
$offset: 2rem;
$dark-color: #030303;
$main-width: 100%;
$side-width: 20%;
$nav-size: 130px;
$title-size: 9vmax;
$easing: cubic-bezier(0.694, 0, 0.335, 1);

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

ul {
  padding: 0;
}

li {
  list-style-type: none;
}

a,
a:visited,
a:hover {
  color: inherit;
  text-decoration: none;
  outline: none;
}

html {
  font-size: 16px;
}

html,
body {
  overflow: hidden;
  height: 100vh;
}

body {
  font-family: $main-font;
  color: $dark-color;
}

%abs {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

@mixin outlined($color: $dark-color, $size: 1px) {
  color: transparent;
  -webkit-text-stroke: $size $color;
}

/* #LAYOUT - START*/

#hero-slider {
  position: relative;
  height: 100vh;
  display: flex;
  background: white;

  &:not(.ready) {
    visibility: hidden;
  }
}

#slideshow {
  position: relative;
  flex: 0 0 $main-width;
  display: flex;
  align-items: flex-end;
}

#slides-main {
  /*  border: 2px solid red; */
  position: absolute;
  top: 0;
  left: 0%;
  height: 100%;
  width: 100%;

  .slide-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 80%;
    width: 30%;
    margin-top: 5rem;
    /* padding: $offset; */
    margin-left: 8rem;
    margin-bottom: 5rem;

    background-position: center;
    background-size: cover;
    z-index: -1;
  }
}

#slides-aux {
  position: relative;
  bottom: 32%;
  left: 36%;
  width: 40%;
  height: 20%;

  .slide-title {
    position: absolute;
    mix-blend-mode: difference;
    /*   margin-bottom: 0.5rem; */
    z-index: 300;
    padding: 0.2rem;
    font-size: 7vw;
    font-weight: 700;
    line-height: 1;
    color: white;
  }

  .slide-text {
    position: absolute;
    right: -10%;
    top: -25%;
    margin-bottom: 10rem;
    mix-blend-mode: difference;
    z-index: 300;
    padding: 0.2rem;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1;
    color: white;
  }
}

#info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 1s $easing;
  .slider-title-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .slider-title {
    position: relative;
    font-size: $title-size;
    line-height: 1.3;
    white-space: nowrap;
    font-weight: 700;
    text-align: center;
    text-indent: -1%;
  }

  .line1 {
    position: absolute;
    top: 0;
    left: 12%;
    height: 8%;
    width: 18px;
    background: yellow;
  }

  .line2 {
    position: absolute;
    bottom: 12%;
    left: 15%;
    height: 12%;
    width: 22px;
    background: blue;
  }
  .line3 {
    position: absolute;
    bottom: 0;
    left: 65%;
    height: 12%;
    width: 22px;
    background: red;
  }
  .line4 {
    position: absolute;
    top: 6%;
    left: 85%;
    height: 10%;
    width: 18px;
    background: $dark-color;
  }
  .line5 {
    position: absolute;
    bottom: 5%;
    left: 90%;
    height: 100px;
    width: 100px;
    color: white;
    border-radius: 100%;
    background: $dark-color;
    text-align: center;
    z-index: 900;
    padding: 35px 20px;
  }

  .about {
    position: absolute;
    bottom: $offset;
    right: $offset;
    text-align: right;
    max-width: 85%;
    font-size: 0.8rem;
    line-height: 1.2;

    a:hover {
      opacity: 0.8;
    }
  }
}

#logo {
  transform: rotate(90deg);
  position: absolute;
  top: 5rem;
  left: 0rem;
  padding-right: 1rem;
  /*  z-index: 200; */

  .logo-text {
    position: relative;
    font-size: 1.2rem;
    font-weight: 500;
    color: black;
  }

  /*  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    background-color: black;
  } */
}
.floating-box {
  background-color: transparent;

  position: absolute;
  z-index: 7000;
  top: 0;
  left: 0;
  height: 22%;
  width: 6%;
}

.floating-text {
  position: absolute;
  transform: rotate(90deg);
  font-size: 1.2rem;
  font-weight: 500;
  color: black;
}

#slider-nav {
  transform: rotate(90deg);
  position: absolute;
  top: 40%;
  right: calc(2% - 220px / 2);
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 500;
  opacity: 0;
  transition: opacity 1s $easing;
  .current {
    color: black;
  }

  %pseudo {
    content: "";
    position: absolute;
    height: 100%;
    width: var(--dynamic-width);
    background-color: black;
    left: 0;
  }

  .sep {
    --dynamic-width: 25%;
    position: relative;
    width: 220px;
    height: 2px;
    margin: 0 1rem;
    background-color: lightgrey;

    &:before {
      @extend %pseudo;
      background-color: black;
      left: 0;
    }
  }

  .total {
    color: $dark-color;
  }

  > span {
    font-size: 1.5rem;
  }
}

/* #LAYOUT - END*/

/* #INITIAL ANIMATION - START */

#hero-slider {
  #logo:after {
    transform: scaleY(0);
    transform-origin: 50% 0;
    transition: transform 0.35s $easing;
  }

  .logo-text {
    display: block;
    transform: translate3d(120%, 0, 0);
    opacity: 0;
    transition: transform 0.8s 0.2s, opacity 0.5s 0.2s;
  }

  .current,
  .sep:before {
    opacity: 0;
    transition: opacity 0.4s 1.3s;
  }

  .line {
    transform-origin: 0% 0;
    transform: scaleY(0);
    transition: transform 0.7s $easing 1s;
  }

  .slider-title {
    overflow: hidden;

    > span {
      display: block;
      transform: translate3d(0, -100%, 0);
      transition: transform 0.5s 1.5s;
    }
  }

  &.ready {
    #logo:after {
      transform: scaleY(1);
    }

    .line {
      transform: scaleX(1);
    }

    .logo-text {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    #info,
    .slider-title > span {
      transform: translate3d(0, 0, 0);
    }

    .current,
    .sep:before {
      opacity: 1;
    }
  }

  &:not(.loaded) {
    .slide:not(:first-child) {
      visibility: hidden;
    }
  }
}

/* #INITIAL ANIMATION - END */

/* #SLIDESHOW - START */
#slides-main {
  overflow: hidden;

  .abs-mask {
    @extend %abs;
    overflow: hidden;
    // will-change: transform;
  }

  .slide {
    @extend %abs;
    overflow: hidden;
    will-change: transform;

    .slide-image {
      will-change: transform;
    }
    .img-1 {
      will-change: transform;
    }

    &.prev {
      z-index: 5;
      transform: translate3d(0, -500%, 0);
      transition: 1s $easing;
      .abs-mask {
        transform: translateY(80%);
        transition: 1s $easing;
      }

      // .slide-image {
      //     transition: transform 0s 1s;
      // }
    }

    &.active {
      z-index: 10;
      transform: translate3d(0, 0, 0);
      transition: transform 1s $easing;

      .abs-mask {
        transform: translate3d(0, 0, 0);
        transition: transform 1s $easing;
      }

      // .slide-image {
      //     transform: scale(1.01);
      //     transition: transform 1s $easing;
      // }
    }

    &:not(.prev):not(.active) {
      z-index: -1;
      transform: translate3d(0, 300%, 0);

      .abs-mask {
        transform: translateY(-450%);
      }
    }

    // &:not(.active) {
    //     .slide-image {
    //         transform: scale(1.21);
    //     }
    // }

    @at-root #hero-slider.prev & {
      &.prev {
        transform: translate3d(0, 300%, 0);

        .abs-mask {
          transform: translate3d(0, -450%, 0);
        }
      }

      &:not(.prev):not(.active) {
        transform: translate3d(0, -500%, 0);

        .abs-mask {
          transform: translate3d(0, 450%, 0);
        }
      }
    }
  }
}

#slides-aux {
  .slide {
    &:not(.active) {
      transform: translateY(-20%);
      opacity: 0;
    }

    &.prev {
      transition: transform 0s 0.9s, opacity 0.2s;
    }

    &.active {
      transition: opacity 0.6s 0.8s,
        transform 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0.8s;
    }
  }
}

/* #SLIDESHOW - END */

/* #RESPONSIVE - START*/
/* Landscape */
@media only screen and (max-device-width: 812px) and (orientation: landscape) {
  #info {
    .line,
    .about {
      display: none;
    }

    .slider-title-wrapper {
      top: auto;
      bottom: 2rem;
      left: calc(70% + 11px);
      transform-origin: 35% -100%;
    }
  }

  #slides-aux {
    top: 0;
  }
}

@media only screen and (max-device-width: 768px) and (orientation: portrait) {
  #info {
    display: none;
  }

  #slides-aux {
    /*    border: 2px solid red; */
    display: flex;
    align-items: flex-end;
    top: 0;

    .slide-title {
      font-size: 3rem;
    }
  }

  #slider-nav {
    right: 2rem;
  }
}

@media only screen and (max-device-width: 680px) and (orientation: portrait) {
  #slides-aux {
    .slide-title {
      font-size: 2.5rem;
    }
  }

  #slider-nav {
    top: 6rem;
    right: auto;
    left: 2rem;
  }
}

/* #RESPONSIVE - END*/

#circle {
  transition: transform 0.8s 0.2s $easing, opacity 0.8s $easing 0.2s;
  mix-blend-mode: difference;
  z-index: 800;
  position: absolute;
  top: 25%;
  left: 12%;
  width: 300px;
  height: 300px; /* as the half of the width */
  /* transition: transform 1s 0.2s $easing; */
  opacity: 0;

  .circle-inner {
    transition: transform 1s 0.4s $easing;
    z-index: 800;
    position: absolute;
    width: 300px;
    height: 150px; /* as the half of the width */
    background-color: transparent;
    border-top-left-radius: 270px; /* 100px of height + 10px of border */
    border-top-right-radius: 270px; /* 100px of height + 10px of border */
  }
}

abs-text {
  /* mix-blend-mode: difference; */
  position: absolute;
  top: 0;
  left: 30%;
  z-index: 100;
  font-size: 4vw;
  color: white;
}

.img-1 {
  z-index: 1000;
  position: absolute;
  top: 20%;
  left: 15%;
  height: 55%;
}

.cursor {
  cursor: pointer;
  height: 2.5rem;
  width: 2.5rem;
  position: absolute;
  z-index: 100000;
  top: 0;
  mix-blend-mode: difference;
  background: yellow;
  border-radius: 100%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    text-align: center;
    font-size: 0.7rem;
    line-height: 0.5rem;
  }
}

.floating-image {
  z-index: 2000;
}

.contact {
  position: absolute;
  top: 0;
  left: 0%;
  height: 100vh;
  width: 100vw;
}

.intro {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .intro-title {
    z-index: 1000;
    padding: 0.8rem;
    font-size: 7vw;
    font-weight: 700;
    line-height: 1;

    color: black;
  }
  .intro-subtitle {
    margin-top: -2rem;
    margin-bottom: 1rem;
    z-index: 1000;
    padding: 0.2rem;
    font-size: 3vw;
    font-weight: 700;
    line-height: 1;
    color: black;
  }
  .textloop {
    margin: 0 0.8rem;
  }

  .line {
    background-color: black;
    transform-origin: 0% 0;
    transform: scaleX(1);
    transition: transform 0.7s $easing 1s;
  }
}

.mask-test {
  position: absolute;
  top: 0;
  left: 0;
  width: 6%;
  height: 25%;

  z-index: 1000;
  background-color: turquoise;
}
